import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: require('./locale/en/common.json'),
        order: require('./locale/en/order.json'),
        auth: require('./locale/en/auth.json')
      },
      ar: {
        common: require('./locale/ar/common.json'),
        order: require('./locale/ar/order.json'),
        auth: require('./locale/ar/auth.json')
      },
    },
    supportedLngs: ['en', 'ar'],
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: [ "path"],
  },
  });

export default i18n;
