import { login } from "../services/authService";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setLocalStorageData,
  clearLocalStorage,
  getLocalStorageData,
} from "../utils/helper";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [authError, setAuthError] = useState(null); // new state for the error message
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    const response = await login(data);
    if (response && response.status === 201) {
      if (response.data && response.data.data && response.data.data.accessToken) {
        setLocalStorageData(
          "authorization-token",
          response.data.data.accessToken
        );
        setAuthToken(response.data.data.accessToken);
        setUser(response.data.data.accessToken);
        setAuthError(null); // clear the error
        navigate("/");
      } else {
        setAuthError('Invalid credentials.'); // set the error
      }
    } else {
      setAuthError('Failed to log in.'); // set the error
    }
  };



  const handleLogout = () => {
    setUser(null);
    setAuthToken(null);
    clearLocalStorage("authorization-token");
    navigate("/login", { replace: true });
  };

  const isAuthenticated = () => {
    if (getLocalStorageData("authorization-token")) {
      return true;
    } else {
      return false;
    }
  };

  const value = {
    user,
    handleLogin,
    handleLogout,
    authToken,
    isAuthenticated,
    authError, // include the authError in the value for context
    setAuthError, // include the method to set the authError in the value for context
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
