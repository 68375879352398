import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { messaging } from "../firebase";
import { getLocalStorageData, setLocalStorageData } from "../utils/helper";
import { getToken } from "firebase/messaging";
import { saveFirebaseToken } from "../services/authService";
export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    token: getLocalStorageData("notification-token"),
  },
  reducers: {
    setFirebaseToken: (state, actions) => {
      state.token = actions.payload;
    },
  },
});

export const generateFirebaseToken = createAsyncThunk(
  "create/firebase-token",
  async (_, { dispatch }) => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      if (!getLocalStorageData("notification-token")) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          //Token Generate
          const token = await getToken(messaging, {
            vapidKey: `${process.env.REACT_APP_FIREBASE_VAPID_KEY}`,
          });
          dispatch(setFirebaseToken(token));
          if (token) {
            setLocalStorageData("notification-token", token);
            
            // Save firebase-token
            const payload = {
              code: 200,
              message: null,
              data: {
                firebase_token: token,
              },
            };
            const response = await saveFirebaseToken(payload);
            if (response.status === 201) {
              // Handle save token success event
            }
          }
        } else if (permission === "denied") {
          alert("You denied the Notifications access to the site");
        }
      }
    }
  }
);

export const { setFirebaseToken } = notificationSlice.actions;

export const getFirebaseToken = (state) => state.notification.token;

export default notificationSlice.reducer;
