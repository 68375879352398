export const setLocalStorageData = (key, item) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(item));
  }
};

export const getLocalStorageData = (key) => {
  if (typeof window !== "undefined") {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : undefined;
  }
};

export const clearLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};

export function isObjectEmpty(value) {
  return (
    Object.prototype.toString.call(value) === "[object Object]" &&
    JSON.stringify(value) === "{}"
  );
}
