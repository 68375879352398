import baseService from "./baseService";

export async function login(data) {
  const response = await baseService.post('/merchant-access-tokens', data);  
  return response
}

export async function saveFirebaseToken(data){
  const response = await baseService.post('/merchant-user', data);  
  return response
}