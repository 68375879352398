import axios from "axios";
import {
  // clearLocalStorage,
  getLocalStorageData,
  // setLocalStorageData,
} from "../utils/helper";
import { Navigate } from "react-router";

const onRequest = (config) => {
  config.headers.common['content-type'] = 'application/json'
  
  if (config.url !== '/merchant-access-tokens') {
    const token = getLocalStorageData("authorization-token");
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error?.response?.status === 401) {
    Navigate("login")
    // Handle expired access-token
  }
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const baseService = setupInterceptorsTo(
  axios.create({
    baseURL: `${process.env.REACT_APP_SPRYKER_BASE_URL}`,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default baseService;
