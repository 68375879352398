import { lazy } from "react";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const AuthLayout = lazy(() => import("../layouts/AuthLayout.js"));
/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const Login = lazy(() => import("../views/auth/Login.js"));
const Register = lazy(() => import("../views/auth/Register.js"));
const OrderList = lazy(() => import("../views/order/orderList.js"));
const OrderDetail = lazy(() => import("../views/order/orderDetail.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        exact: true,
        element: (
            <Starter />
        ),
      },
      {
        path: "/orders",
        exact: true,
        element: (
            <OrderList />
        ),
      },
      {
        path: "/orders/:id",
        exact: true,
        element: (
          <OrderDetail />
        ),
      }
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [{ path: "/login", exact: true, element: <Login /> }],
  },
  {
    path: "/register",
    element: <AuthLayout />,
    children: [{ path: "/register", exact: true, element: <Register /> }],
  },
];

export default ThemeRoutes;
