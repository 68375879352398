import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  const routing = useRoutes(Themeroutes);
  document.body.dir = i18n.dir();
  
  // const { t } = useTranslation("auth");
  // const trans = `${t("login")}`;
  // console.log("translation", trans);

  return <div className="dark">{routing}</div>;
};

export default App;
